import RoleEnum from '@/common/role.enum'

export default [
  // {
  //   title: 'Announcement',
  //   route: 'announcement',
  //   icon: 'HomeIcon'
  // },
  {
    header: 'User Management',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Group',
    route: 'user-group',
    icon: 'UsersIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Team',
    route: 'user-team',
    icon: 'UsersIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'User',
    route: 'user-user',
    icon: 'UserIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    header: 'Categories Management',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Categories',
    route: 'categories-management',
    sub: ['categories-add'],
    icon: 'ListIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    header: 'Customer Configuration',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Customer Group',
    route: 'customer-group',
    icon: 'UsersIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Customer Type',
    route: 'customer-type',
    icon: 'UsersIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Company Department',
    route: 'company-department',
    icon: 'UsersIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Company Group',
    route: 'company-group',
    icon: 'UsersIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Company Center',
    route: 'company-center',
    icon: 'UsersIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    header: 'Ticket Configuration',
    role: [RoleEnum.SUPER_ADMIN]
  },
  // {
  //   title: 'Call Category',
  //   route: 'categories-call',
  //   icon: 'ListIcon'
  // },
  {
    title: 'Contact Channels',
    route: 'ticket-contact',
    icon: 'BookIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Ticket Type',
    route: 'ticket-type',
    icon: 'TagIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Plant',
    route: 'plant',
    icon: 'FileTextIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Skill Name',
    route: 'skill-name',
    icon: 'FileTextIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    header: 'Knowledge',
    role: [RoleEnum.SUPER_ADMIN, RoleEnum.SUPERVISOR, RoleEnum.MANAGEMENT, RoleEnum.MANAGER]
  },
  {
    title: 'Knowledge Base',
    route: 'other-knowledge',
    sub: ['other-knowledge-add'],
    icon: 'SunIcon',
    role: [RoleEnum.SUPER_ADMIN, RoleEnum.SUPERVISOR, RoleEnum.MANAGEMENT, RoleEnum.MANAGER]
  },
  {
    title: 'Knowledge Type',
    route: 'knowledge-type',
    icon: 'SunIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Knowledge Categories Mapping',
    route: 'knowledge-categories-mapping',
    icon: 'SunIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    header: 'Others',
    role: [RoleEnum.SUPER_ADMIN, RoleEnum.SUPERVISOR, RoleEnum.MANAGEMENT, RoleEnum.MANAGER]
  },
  {
    title: 'Holidays',
    route: 'other-working',
    icon: 'CalendarIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Email Group',
    route: 'email-group',
    icon: 'MailIcon',
    role: [RoleEnum.SUPER_ADMIN, RoleEnum.SUPERVISOR, RoleEnum.MANAGEMENT, RoleEnum.MANAGER]
  }
]
